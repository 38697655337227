import { React, useState, useEffect, useContext } from "react";
import { Async } from "react-async";
import Select from "react-select";

import "./App.css";
import { Solution } from './solution';

function PartnerName({partner}) {
  const solution = useContext(Solution);
  return (
    <Async
      promiseFn={solution.partner_name} watch={partner}
      partner={partner}
    >
      <Async.Pending>...</Async.Pending>
      <Async.Fulfilled>
        {(name) => <span>{name}</span>}
      </Async.Fulfilled>
    </Async>
  );
}

function AdvertiserName({advertiser}) {
  const solution = useContext(Solution);
  return (
    <Async
      promiseFn={solution.advertiser_name} watch={advertiser}
      advertiser={advertiser}
    >
      <Async.Pending>...</Async.Pending>
      <Async.Fulfilled>
        {(name) => <span>{name}</span>}
      </Async.Fulfilled>
    </Async>
  );
}

function CampaignName({campaign}) {
  const solution = useContext(Solution);
  return (
    <Async
      promiseFn={solution.campaign_name} watch={campaign}
      campaign={campaign}
    >
      <Async.Pending>...</Async.Pending>
      <Async.Fulfilled>
        {(name) => <span>{name}</span>}
      </Async.Fulfilled>
    </Async>
  );
}

function AsyncRejected() {
  return (
    <Async.Rejected>
      {(error) => (
        <div>
          <pre>Error: {error.message} {error.response.statusText}</pre>
          <pre>{error.response.data}</pre>
        </div>
      )}
    </Async.Rejected>
  );
}

export default function App() {
  const [bidlist, setBidlist] = useState(null);
  const [changeidx, setChangeidx] = useState(0);
  const solution = useContext(Solution);

  const mark_change = () => setChangeidx(changeidx+1);

  const select_bidlist = <div>
      <Async promiseFn={solution.bidlists}>
        <Async.Pending>Loading...</Async.Pending>
        <Async.Fulfilled>
          {(bidlists) => (
            <div>
              <Select searchable isClearable
                options={bidlists.map(b => ({label: b, value: b}))}
                value={bidlist} onChange={setBidlist}
              />
            </div>
          )}
        </Async.Fulfilled>
        <AsyncRejected/>
      </Async>
  </div>;

  const associated_campaigns = !bidlist ? (null) : (
    <div>
      <table>
        <thead>
          <tr>
            <td className="data">Partner ID</td>
            <td>Partner Name</td>
            <td className="data">Advertiser ID</td>
            <td>Advertiser Name</td>
            <td>Campaign ID</td>
            <td>Campaign Name</td>
          </tr>
        </thead>
        <tbody>
          <Async promiseFn={solution.bidlist_associations} name={bidlist.value} watch={`${bidlist?.value}#${changeidx}`}>
            <Async.Pending>
              <tr>
                <td>...</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>

            </Async.Pending>
            <Async.Fulfilled>{(campaigns) => {
                return campaigns.map(campaign => 
                  <tr key={`${campaign.partner}#${campaign.advertiser}#${campaign.campaign}#${changeidx}`}>
                    <td><pre>{campaign.partner}</pre></td>
                    <td><PartnerName partner={campaign.partner}/></td>
                    <td><pre>{campaign.advertiser}</pre></td>
                    <td><AdvertiserName advertiser={campaign.advertiser}/></td>
                    <td><pre>{campaign.campaign}</pre></td>
                    <td><CampaignName campaign={campaign.campaign}/></td>
                      <button 
                        onClick={(e) => {
                          solution.deassociate(bidlist.value, campaign.partner, campaign.advertiser, campaign.campaign);
                          mark_change();
                        }}
                      >
                        x
                      </button>
                  </tr>
                );
              }}
            </Async.Fulfilled>
            <AsyncRejected/>
          </Async>
        </tbody>
      </table>
      <button onClick={mark_change}>refresh</button>
    </div>
  );

  return (
    <div>
      <h1>Attention Bidlists</h1>
      {select_bidlist}
      <br/>
      {associated_campaigns}
      <br/>
      {bidlist ? <AddCampaign bidlist={bidlist} mark_change={mark_change}/> : <div></div>}
    </div>
  );
};


function AddCampaign({bidlist, mark_change}) {
  const solution = useContext(Solution);
  const [partner, setPartner] = useState(null);
  const [advertiser, setAdvertiser] = useState(null);
  const [campaign, setCampaign] = useState(null);

  useEffect(() => {
    setAdvertiser(null);
  }, [partner]);
  useEffect(() => {
    setCampaign(null);
  }, [advertiser]);

  const partner_option = (p) => ({
    label: `${p.PartnerId}: ${p.PartnerName}`,
    value: p.PartnerId,
  });
  const advertiser_option = (a) => ({
    label: `${a.AdvertiserId}: ${a.AdvertiserName}`,
    value: a.AdvertiserId,
  });
  const campaign_option = (c) => ({
    label: `${c.CampaignId}: ${c.CampaignName}`,
    value: c.CampaignId,
  });

  const ErrorMessage = (error) => <div>Error: {error.message}</div>;
  const SelectPartner = (partners) => (
    <Select
      searchable={true}
      isClearable={true}
      options={partners.map(partner_option)}
      value={partner}
      onChange={setPartner}
    />
  );
  const SelectAdvertiser = (advertisers) => (
    <Select
      searchable={true}
      isClearable={true}
      options={advertisers.map(advertiser_option)}
      value={advertiser}
      onChange={setAdvertiser}
    />
  );
  const SelectCampaign = (campaigns) => (
    <Select
      searchable={true}
      isClearable={true}
      options={campaigns.map(campaign_option)}
      value={campaign}
      onChange={setCampaign}
    />
  );

  return (
    <div>
      <div className="container">
        <div className="section">
          <h3>Add campaign to bidlist</h3>
          <label>Partner</label>
          <Async
            promiseFn={solution.partners}
            onResolve={(partners) => {
              if (partners.length === 1)
                setPartner(partner_option(partners[0]));
            }}
          >
            <Async.Pending>
              <Select isDisabled={true} isLoading={true} />
            </Async.Pending>
            <Async.Rejected>{ErrorMessage}</Async.Rejected>
            <Async.Fulfilled>{SelectPartner}</Async.Fulfilled>
          </Async>
          <label>Advertiser</label>
          {!partner ? (
            <Select isDisabled={true} />
          ) : (
            <Async
              promiseFn={solution.advertisers}
              watch={partner}
              partner={partner.value}
            >
              <Async.Pending>
                <Select isDisabled={true} isLoading={true} />
              </Async.Pending>
              <Async.Rejected>{ErrorMessage}</Async.Rejected>
              <Async.Fulfilled>{SelectAdvertiser}</Async.Fulfilled>
            </Async>
          )}
          <label>Campaign</label>
          {!advertiser ? (
            <Select isDisabled={true} />
          ) : (
            <Async
              promiseFn={solution.campaigns}
              watch={advertiser}
              advertiser={advertiser.value}
            >
              <Async.Pending>
                <Select isDisabled={true} isLoading={true} />
              </Async.Pending>
              <Async.Rejected>{ErrorMessage}</Async.Rejected>
              <Async.Fulfilled>{SelectCampaign}</Async.Fulfilled>
            </Async>
          )}
        </div>
      </div>
      <div>
        <button
          onClick={() => {
            solution.associate(bidlist?.value, partner?.value, advertiser?.value, campaign?.value);
            mark_change();
          }}
          disabled={!bidlist || !partner || !advertiser || !campaign}>
          associate
        </button>

      </div>
    </div>
  );
}