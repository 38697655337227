import React from "react";
import ReactDOM from "react-dom/client";
import { AuthProvider, useAuth } from "oidc-react";
import "./index.css"
import App from "./App";
import { Solution, SolutionApi } from "./solution";
import { AuthQA, AuthProd } from "./config";

function LoginContainer({children}) {
  const auth = useAuth();
  if (auth && auth.userData) {
    console.log(auth);
    const solution = new SolutionApi(auth.userData?.id_token);

    return (
      <Solution.Provider value={solution}>
        <strong>Logged in! 🎉</strong> {auth.userData?.profile.email} 
        {children}
      </Solution.Provider>
    );
  }
  return <div>Not logged in! Redirecting to TTD SSO...</div>;
}

const auth = window.origin.includes("sa-custom-solutions.com") ? AuthProd : AuthQA;

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <AuthProvider
      authority={auth.authority}
      clientId={auth.clientId}
      redirectUri={window.origin}
      onSignIn={async (user) => {
        console.log(user);
        window.location.search = "";
      }}
    >
      <LoginContainer><App/></LoginContainer>
    </AuthProvider>
  </React.StrictMode>,
);
