import { createContext } from "react";
import axios from "axios";

export const Solution = createContext(null);

export class SolutionApi {
  http;

  constructor(token) {
    this.http = axios.create({
      baseURL: `${window.origin}/api`,
      headers: {
        Authorization: token,
      },
      withCredentials: true,
    });
  }
  bidlists = async () => {
    const r = await this.http.get("/bidlists");
    console.log("got bidlists", r.data);
    return r.data;
  };

  bidlist_associations = async ({name}) => {
    const r = await this.http.get(`/bidlist/${name}/associations`)
    console.log("got bidlist", name, "associations", r.data);
    return r.data;
  }

  associate = async (bidlist, partner, advertiser, campaign) => {
    const r = this.http.put("/bidlist", null, {
      params: {
        bidlist: bidlist,
        partner: partner,
        advertiser: advertiser,
        campaign: campaign,
      },
    });
    console.log(
      `associate ${bidlist} to partner=${partner} advertiser=${advertiser} campaign=${campaign}`,
      r,
    );
    return r.status;
  };

  deassociate = async (bidlist, partner, advertiser, campaign) => {
    const r = this.http.delete("/bidlist", {
      params: {
        bidlist: bidlist,
        partner: partner,
        advertiser: advertiser,
        campaign: campaign,
      },
    });
    console.log(
      `deassociate ${bidlist} partner=${partner} advertiser=${advertiser} campaign=${campaign}`,
      r,
    );
    return r.status;
  };

  partners = async () => {
    console.log("get partners");
    const r = await this.http.get("/partners");
    return r.data;
  };

  advertisers = async ({ partner }) => {
    console.log("get advertisers for partner", partner);
    const r = await this.http.get(`/partner/${partner}/advertisers`);
    return r.data;
  };

  campaigns = async ({ advertiser }) => {
    console.log("get campaigns for advertiser", advertiser);
    const r = await this.http.get(`/advertiser/${advertiser}/campaigns`);
    return r.data;
  };

  partner_name = async ({ partner }) => {
    console.log("get name for partner", partner);
    return (await this.http.get(`/partner/${partner}/name`)).data;
  };

  advertiser_name = async ({ advertiser }) => {
    console.log("get name for advertiser", advertiser);
    return (await this.http.get(`/advertiser/${advertiser}/name`)).data;
  };

  campaign_name = async ({ campaign }) => {
    console.log("get name for campaign", campaign);
    return (await this.http.get(`/campaign/${campaign}/name`)).data;
  };
}
