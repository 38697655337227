
export const AuthQA = {
    authority: "https://cognito-idp.us-east-1.amazonaws.com/us-east-1_KsljhLyVV",
    clientId: "7mlctv0pbfc0ccmfc9nlscgsfi",
}

export const AuthProd = {
    authority: "https://cognito-idp.us-east-1.amazonaws.com/us-east-1_JD4mXIgJB",
    clientId: "678e7auif3ei6kal2q5bnmuecb",
}
